<template>
  	<div class="experience-container">
		<div>
			<h2>Tutoriály na mém BMW E46</h2>
			<youtube-card v-for="(video, index) in video_data" :key="index" :title="video.title" :text="video.text" :videoId="video.videoId" :height="video.height" :width="video.width" />
		</div>
	</div>


</template>

<script>
import YoutubeCard from '@/components/ui/youtubeCard/youtubeCard.vue';

export default {

	components: {
		YoutubeCard,
	},

	data: () => {
		return {
			video_data: [
				{
					title: 'Retrofit tempomatu',
					text: ` <p></p>
							<p></p>`,
					videoId: 'Uc_ABknNLE8',
					height: '360',
					width: '550'
				},
				{
					title: 'Diagnóza missfire',
					text: ` <p></p>`,
					videoId: 'xS2kIFRWbmk',
					height: '360',
					width: '550'
				},
				{
					title: 'Výměna torpéda',
					text: ` <p></p>`,
					videoId: '82NWlH0r5AE',
					height: '360',
					width: '550'
				},
				{
					title: 'Výměna filtru a oleje',
					text: ` <p></p>`,
					videoId: 'sTTGDvKjxl8',
					height: '360',
					width: '550'
				},
				{
					title: 'Výměna intake camshaft sensoru',
					text: ` <p></p>`,
					videoId: 'cXEDpVESsEA',
					height: '360',
					width: '550'
				},
				{
					title: 'Výměna systému odvětrávání CCV',
					text: ` <p></p>`,
					videoId: 'DlkZ_JoTB38',
					height: '360',
					width: '550'
				},
				{
					title: 'Výměna předních tlumičů',
					text: ` <p></p>`,
					videoId: 'tGkK-qlyDWE',
					height: '360',
					width: '550'
				},
  			]
		}
	}
}
</script>

<style>
.experience-container {
    margin: auto;
}

.experience-container a{
    color: black;
}

.work-experience {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    margin-bottom: 1.5rem;
}

.work-experience ul {
    margin: 0;
}

.work-experience li {
    list-style: none;
}

.work-experience-info {
    flex: 0 0 50%;
    margin: 0 2rem 1rem 0;
}

.work-experience-description {
    flex: 1 1;
}

@media (min-width: 500px) {
    .experience-container {
        width: 80%;
        padding: none;
    }
}
</style>