<template>
  	<div class="card">
            <div v-if="title" class="card-text">
                <h2>{{title}}</h2>
                <div v-html="text"></div>
            </div>    

            <div v-if="videoId" :class="title ? 'card-img' : 'card-img card-img-no-text'"><youtube :video-id="videoId" :player-height="height" :player-width="width"></youtube></div>
    </div>


</template>

<script>

export default {
    props: ["title", "text", "videoId", "height", "width"],
}

</script>

<style>
    .card {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        /* border-bottom: 0.3rem solid black; */
        margin-bottom: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .card-img {
        flex: 0 0 50%;
        margin: 1rem 2rem 1rem 0;
        position: relative; 
        padding-bottom: 56.25%; 
        padding-top: 30px; 
        height: 0; 
        width: 100%;
        overflow: hidden;
    }

    .card-img-no-text {
        flex: auto;
    }

    .card-img img{
        width: 100%;
        box-sizing: border-box;
        box-shadow: 0.5rem 0.5rem 0.5rem 0px rgba(0,0,0,0.69);
    }

    .card-img iframe{
        margin: 0 auto;
        display: block;
    }

    .card-img iframe, .card-img object, .card-img embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; } 

    /* .video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }
    .video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; } */


    .card-text {
        flex: 1 1;
        color: rgb(0, 0, 0);
    }

    .card-text a{
        color: rgb(0, 0, 0);
    }

    .card-text h2 {
        color: rgb(0, 0, 0);
    }

    .card-text h3 {
        color: rgb(0, 0, 0);
    }
</style>